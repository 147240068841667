// Polaroid.tsx
export const Polaroid = () => (
  <svg width="32" height="32" viewBox="0 0 512 512">
    <polygon
      fill="#AFB6BB"
      points="465.204,8.258 465.204,399.14 426.667,399.14 426.667,327.57 426.667,46.796 85.333,46.796 85.333,294.538 85.333,399.14 46.796,399.14 46.796,8.258 "
    />
    <path
      fill="#FFD248"
      d="M327.57,96.344c27.362,0,49.548,22.187,49.548,49.548s-22.187,49.548-49.548,49.548c-27.362,0-49.548-22.187-49.548-49.548S300.208,96.344,327.57,96.344z"
    />
    <path
      fill="#31C0D8"
      d="M426.667,46.796V327.57L244.989,217.462L85.333,294.538V46.796H426.667z M377.118,145.892c0-27.362-22.187-49.548-49.548-49.548c-27.362,0-49.548,22.187-49.548,49.548s22.187,49.548,49.548,49.548C354.932,195.441,377.118,173.254,377.118,145.892z"
    />
    <polygon
      fill="#23A24D"
      points="426.667,327.57 426.667,399.14 85.333,399.14 85.333,294.538 244.989,217.462 "
    />
    <polygon
      fill="#E7ECED"
      points="465.204,399.14 465.204,503.742 46.796,503.742 46.796,399.14 85.333,399.14 426.667,399.14 "
    />
    <path
      d="M38.538,0v512h434.925V0H38.538z M456.946,16.516v374.366h-22.022V38.538H77.075v352.344H55.054V16.516H456.946z
		M418.409,312.908L245.435,208.076L93.591,281.381V55.054h324.817V312.908z M93.591,299.72l150.952-72.872l173.865,105.374v58.66
		H93.591V299.72z M55.054,495.484v-88.086h401.892v88.086H55.054z"
    />
    <rect x="388.129" y="445.935" width="44.043" height="16.516" />
    <rect x="79.828" y="445.935" width="27.527" height="16.516" />
    <rect x="305.548" y="445.935" width="66.065" height="16.516" />
    <path
      d="M327.57,203.699c31.874,0,57.806-25.933,57.806-57.806s-25.933-57.806-57.806-57.806s-57.806,25.933-57.806,57.806
		S295.696,203.699,327.57,203.699z M327.57,104.602c22.768,0,41.29,18.522,41.29,41.29c0,22.768-18.522,41.29-41.29,41.29
		c-22.768,0-41.29-18.522-41.29-41.29S304.802,104.602,327.57,104.602z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
