// QuestionMark.tsx
export const QuestionMark = () => {
  // Determine if dark mode is active
  const isDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  // Set the fill color based on dark mode status
  const fillColor = isDarkMode ? "#E6E3D3" : "black";
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="12px"
      height="12px"
      viewBox="0 0 512 512"
      style={{ transform: "translateY(2px)" }}
    >
      <g>
        <g>
          <path
            fill={fillColor}
            d="M256,0C114.609,0,0,114.609,0,256c0,141.391,114.609,256,256,256c141.391,0,256-114.609,256-256
			C512,114.609,397.391,0,256,0z M256,472c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472
			z"
          />
          <rect x="240" y="352" width="32" height="32" fill={fillColor} />
          <path
            fill={fillColor}
            d="M317.734,150.148c-6.484-6.625-14.688-11.922-24.767-16.031c-10.202-4.102-22.172-6.117-36.28-6.117
			c-11.97,0-22.875,2.016-32.781,6.117c-9.938,4.109-18.5,9.773-25.688,17.125c-7.125,7.289-12.672,14.508-16.5,24.773
			C177.906,186.281,176,192,176,208h32.656c0-16,4.234-28.109,12.938-38.516c8.594-10.453,20.266-14.82,35.093-14.82
			c14.438,0,25.234,3.914,32.172,10.938c6.875,7.023,10.392,17.086,10.392,29.797c0,9.883-3.25,18.758-9.733,26.492
			c-6.375,7.75-13.359,15.297-20.845,22.438c-7.594,7.141-13.672,14.766-19.953,22.641c-6.281,7.875-8.72,17.046-8.72,27.499V320h32
			v-13.75c0-8.203,1.203-15.312,4.406-21.516c3.094-6.219,6.953-11.859,11.844-16.892c4.734-5.094,9.812-10,15.469-14.828
			c5.5-4.766,10.781-9.859,15.531-15.172c4.844-5.344,8.875-11.344,11.938-17.969c3.22-6.625,4.828-14.406,4.828-23.477
			c0-7.875-1.422-15.891-4.391-24.039C328.719,164.148,324.031,156.766,317.734,150.148z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
